export enum ROLE {
  ManageProducts = "ManageProducts",
  ConfirmProducts = "ConfirmProducts",
  MPIAgent = "MPIAgent",
  Forbidden = "Forbidden", // used for testing unauthorized access, will remove later
}

export enum PERMISSION_CODE {
  EXPORT_REQUEST_VIEW = "export_requests:view",
  EXPORT_REQUEST_EDIT = "export_requests:edit",
  EXPORT_REQUEST_MPI_REPRESENT = "export_requests:mpi_represent",

  PRODUCT_VIEW = "products:view",
  PRODUCT_EDIT = "products:edit",
  PRODUCT_CONFIRM = "products:confirm",
  PRODUCT_ADD = "products:add",
  PRODUCT_DOWNLOAD = "products:download",

  PRODUCT_PACKAGING_RUN_EDIT = "product:packaging_run:edit",

  PRODUCT_MPI_EDIT = "products:mpi_edit",

  REPLACEMENT_EDIT = "replacement:create",
  REPLACEMENT_CANCEL = "replacement:cancel",
}

export enum FEATURE_FLAGS {
  ESTAR_EIP_POLLING = "estarEipPolling",
  FEATURE_FLAG_CERTIFICATE_COPY = "ffCertificateCopy",
  FEATURE_FLAG_CERTIFICATE_REPLACEMENT_V2 = "ffCertificateReplacementV2",
  FEATURE_FLAG_EXPORT_CERTIFICATE_DOWNLOAD = "ffExportCertificatesDownload",
  FEATURE_FLAG_PRODUCTS_DOWNLOAD = "ffProductsDownload",
}

export const ApiProductPermissionErrorMessage = "Action only allowed for own"

export interface Route {
  label: string
  pathname: RegExp
  path: string
  access?: ROLE[]
}
/**
 * access: the roles that can access the route, if not provided, all roles can access the route
 * @readonly
 */
export const dfeRoutes: readonly Route[] = Object.freeze([
  {
    label: "Home",
    pathname: RegExp(/^\/$/),
    path: "/",
  },
  {
    label: "Export request",
    pathname: RegExp(/^(\/(export-request)(\/.*)?|\/)?$/),
    path: "/export-request",
    access: [ROLE.ManageProducts, ROLE.MPIAgent],
  },
  {
    label: "View all requests",
    pathname: RegExp(/^(\/(requests)(\/.*)?|\/)?$/),
    path: "/requests",
    access: [ROLE.ManageProducts, ROLE.MPIAgent],
  },
  {
    label: "View all products",
    pathname: RegExp(/^(\/(products)(\/.*)?|\/)?$/),
    path: "/products",
    access: [ROLE.ManageProducts, ROLE.ConfirmProducts, ROLE.MPIAgent],
  },
])

export const dfeAccessRoutes: readonly Route[] = Object.freeze([
  {
    label: "Terms and Conditions",
    pathname: RegExp(/^(\/(terms-and-conditions)(\/.*)?|\/)?$/),
    path: "/terms-and-conditions",
    access: [ROLE.ManageProducts, ROLE.ConfirmProducts, ROLE.MPIAgent],
  },
])
