import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Stack,
    TextField,
    Typography,
} from "@mui/material"
import { CloseIcon, ExpandAllIcon, SmallErrorIcon } from "@tc/ui-dfe/icons"
import type { ReasonDialogProps } from "./types"


export const FormErrorPrompt = ({ message }: { message?: string }) => {
    if (!message) {
        return null
    }

    return (
        <Stack direction="row" alignItems="center" gap={0.5}>
            <SmallErrorIcon
                sx={{
                    width: 18,
                    fill: (theme) => theme.palette.error.main,
                }}
            />
            <Typography variant="body2" color="error">
                {message || " "}
            </Typography>
        </Stack>
    )
}

export const ReasonDialog = (props: ReasonDialogProps) => {
    const {
        title,
        message,
        confirmLabel = "Confirm",
        cancelLabel,
        cancelProps,
        confirmProps,
        open,
        placeholder,
        cancelDialog,
        handleConfirm,
        error,
        setError,
        reason,
        setReason,
    } = props

    return (
        <Dialog open={open} onClose={cancelDialog}>
            <DialogTitle width="100%" variant="h2">
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <Typography variant="h6">{title}</Typography>
                    <Stack direction="row" spacing={1}>
                        <IconButton size="small">
                            <ExpandAllIcon sx={{ transform: "rotate(45deg)" }} fontSize="small" />
                        </IconButton>
                        <IconButton size="small" onClick={cancelDialog}>
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </Stack>
                </Stack></DialogTitle>
            <DialogContent>
                {message}
                <TextField
                    fullWidth
                    multiline
                    rows={3}
                    variant="outlined"
                    value={reason}
                    placeholder={placeholder}
                    sx={{
                        "& .mui-i8rutw-MuiFormHelperText-root": {
                            marginLeft: "0 !important"
                        }
                    }}
                    onChange={(e) => {
                        setReason(e.target.value);
                        setError("");
                    }}
                    error={!!error}
                    helperText={<FormErrorPrompt message={error} />}
                />
            </DialogContent>
            <DialogActions sx={{ padding: "5px 24px" }}>
                {cancelLabel && <Button data-testid="cancelBtn"
                    aria-label="Cancel"
                    variant="outlined"
                    size="small"
                    color="inherit" onClick={cancelDialog}
                    {...cancelProps}>
                    {cancelLabel}
                </Button>}
                {confirmLabel && <Button onClick={handleConfirm}
                    data-testid="confirmBtn"
                    aria-label="Confirm"
                    variant="contained"
                    size="small"
                    color="error" {...confirmProps}>
                    {confirmLabel}
                </Button>}
            </DialogActions>
        </Dialog>
    )
}
