"use client"
import { initClient, ssr } from "@tc/ui-dfe/utils/libs/urql/exchanges"
import { AuthUser, Session } from "@tc/ui-dfe/utils/types"
import { ROLE } from "@tc/ui-permission/utils"
import { UrqlProvider } from "@urql/next"
import { intersection, isEmpty } from "lodash"
import { SessionProvider, signOut, useSession } from "next-auth/react"
import { useRouter } from "next/navigation"
import {
  createContext,
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react"
export interface AuthProviderProps {
  children: React.ReactNode
  APP_SYNC_URL: string
  logoutUrl?: string
}

interface AuthUserProviderProps {
  children: React.ReactNode
  logoutUrl?: string
}
interface AuthUserContextProps {
  authUser?: AuthUser
  checkExistInRoles?: (wsmp_number?: string) => boolean
  hasPartyAccess?: (premiseId: string[]) => boolean
}
interface WSMPRoles {
  contact: {
    id: string
  }
}

const getAuthSession = async () => {
  const session = await fetch("/api/auth/session")
    .then((res) => res.json())
    .catch(() => null)
  return session
}

const createClient = initClient(getAuthSession, signOut)
export const UrqlClientProvider = ({
  children,
  APP_SYNC_URL,
}: AuthProviderProps) => {
  const client = useMemo(() => {
    return createClient(APP_SYNC_URL)
  }, [])
  return (
    <UrqlProvider client={client} ssr={ssr}>
      {children}
    </UrqlProvider>
  )
}

const AuthUserContext = createContext<AuthUserContextProps>({})
export const AuthUserProvider = ({
  children,
  logoutUrl,
}: AuthUserProviderProps) => {
  const [authUser, setAuthUser] = useState<AuthUser>()
  const router = useRouter()
  const session = useSession({
    required: true,
    onUnauthenticated() {
      router.push("/signin")
    },
  })
  useEffect(() => {
    const sessionUser = (session?.data as Session)?.user
    if (
      !intersection(
        [ROLE.ConfirmProducts, ROLE.ManageProducts, ROLE.MPIAgent],
        sessionUser?.roles,
      ).some(Boolean)
    ) {
      localStorage.setItem("signin", "/signin")
      signOut({ callbackUrl: logoutUrl })
      return
    }
    setAuthUser(sessionUser)
  }, [(session?.data as Session)?.user?.party_id])

  const checkExistInRoles = useCallback(
    (wsmp_number?: string) => {
      const risk_measures = authUser?.risk_measures
      if (isEmpty(risk_measures)) return false
      if (!wsmp_number) return false
      return risk_measures?.includes(wsmp_number) as boolean
    },
    [authUser],
  )
  const hasPartyAccess = useCallback(
    (premiseId: string[]) => {
      if (intersection([ROLE.MPIAgent], authUser?.roles).some(Boolean))
        return true
      if (!authUser?.party_id) return false
      return premiseId.includes(authUser?.party_id)
    },
    [authUser],
  )
  return (
    <AuthUserContext.Provider
      value={{ authUser, checkExistInRoles, hasPartyAccess }}
    >
      {children}
    </AuthUserContext.Provider>
  )
}
export const AuthProvider = memo(
  ({ children, APP_SYNC_URL, logoutUrl }: AuthProviderProps) => {
    return (
      <UrqlClientProvider APP_SYNC_URL={APP_SYNC_URL}>
        <AuthUserProvider logoutUrl={logoutUrl}>{children}</AuthUserProvider>
      </UrqlClientProvider>
    )
  },
)
AuthProvider.displayName = "AuthProvider"

export const useAuth = () => {
  return useContext(AuthUserContext)
}
export default SessionProvider
