import React, { createContext, useContext, useState, ReactNode } from "react";
import { ReasonDialogContextType, ReasonDialogProviderProps, ReasonDialogResult } from "./types";
import { ReasonDialog } from "./ReasonDialog";

const ReasonDialogContext = createContext<ReasonDialogContextType>({
    openDialog: () => Promise.resolve({ confirmed: false, reason: "" }),
});

export const ReasonDialogProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [modal, setModal] = useState<ReasonDialogProviderProps & { open: boolean }>({
        open: false,
        title: "",
        message: null,
    });
    const [reason, setReason] = useState<string>("");
    const [error, setError] = useState<string>("");
    const [resolvePromise, setResolvePromise] = useState<(result: ReasonDialogResult) => void>(() => () => { });

    const openDialog = (options: ReasonDialogProviderProps): Promise<ReasonDialogResult> => {
        return new Promise((resolve) => {
            setModal({ ...options, open: true });
            setResolvePromise(() => resolve);
        });
    };

    const closeDialog = () => {
        setModal({ open: false, title: "", message: null });
        setReason("");
        setError("");
    };

    const handleConfirm = () => {
        if (modal.required && !reason?.trim()) {
            setError("This field is required.");
            return;
        }
        if (modal.maxLength && (!reason || reason?.length > modal.maxLength)) {
            setError(`Maximum length is ${modal.maxLength} characters.`);
            return;
        }
        resolvePromise({ confirmed: true, reason });
        closeDialog();
    };

    const handleCancel = () => {
        resolvePromise({ confirmed: false, reason: "" });
        closeDialog();
    };

    return (
        <ReasonDialogContext.Provider value={{ openDialog }}>
            {children}
            <ReasonDialog
                {...modal}
                cancelDialog={handleCancel}
                handleConfirm={handleConfirm}
                setError={setError}
                setReason={setReason}
                error={error}
            ></ReasonDialog>
        </ReasonDialogContext.Provider>
    );
};

export const useReasonDialog = (): ReasonDialogContextType => {
    return useContext(ReasonDialogContext);
};
